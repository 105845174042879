<template>
  <div class="content-add-operation global-content-detail-all">

    <div class="global-content-detail" >
    <div id="iHeaderDetail" ref="iHeaderDetail">
      <header-detail-component-global sTextDetail="Creación de una operación" sBackHref="Provider" />
      <v-breadcrumbs :items="itemsBreadcrumbs" divider="/"></v-breadcrumbs>
    </div>
      <!-- #region INPUTS ADD OPERATIONS -->
      <v-container fluid>
        <v-row>
          <v-col cols="12" sm="4" md="4" lg="4" xl="4">
            <v-select v-model="sTypeOperation" :items="itemsTypeOperation" label="Tipo" class="global-select"
              color="var(--primary-color-border-menu-profile)" background-color="var(--primary-color-menu)"
              @change="validateFormOperation()" outlined>
              <template slot="label">
                <span>Tipo de operación<span class="important-data">*</span></span>
              </template>
            </v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="4" md="4" lg="4" xl="4">
            <v-text-field v-model="sDriverLicensePlate" label="Placa de la unidad" placeholder="Placa de la unidad..."
              class="global-text-field" color="var(--primary-color-border-input)"
              background-color="var(--primary-color-menu)" persistent-placeholder outlined maxlength="50"
              @keyup="validateFormOperation()">
              <template slot="label">
                <span>Placa de la unidad
                  <span class="important-data"></span></span>
              </template>
            </v-text-field>
          </v-col>
          <v-col cols="12" sm="4" md="4" lg="4" xl="4">
            <v-text-field v-model="sDriverFullName" label="Nombre del transportista"
              placeholder="Nombre completo del transportista..." class="global-text-field"
              color="var(--primary-color-border-input)" background-color="var(--primary-color-menu)"
              persistent-placeholder outlined maxlength="50" @keyup="validateFormOperation()">
              <template slot="label">
                <span>Nombre del transportista
                  <span class="important-data">*</span></span>
              </template>
            </v-text-field>
          </v-col>
          <v-col v-if="sTypeOperation == 1" cols="12" sm="4" md="4" lg="4" xl="4">
            <v-select v-model="sTypeProvider" @change="validateFormOperation()" item-text="text" item-value="value"
              :items="itemsTypeProvider" label="Tipo de proveedor" class="global-select"
              color="var(--primary-color-border-menu-profile)" background-color="var(--primary-color-menu)" outlined>
              <template slot="label">
                <span>Tipo de proveedor<span class="important-data">*</span></span>
              </template>
            </v-select>
          </v-col>

          <v-col cols="12" sm="4" md="4" lg="4" xl="4">
            <v-select v-model="sTypeProviderOrCustomerOrder" :items="itemsTypeProviderOrCustomerOrder" label="Tipo"
              class="global-select" color="var(--primary-color-border-menu-profile)"
              background-color="var(--primary-color-menu)" @change="validateFormOperation()" outlined>
              <template slot="label">
                <span>{{ sLabelTypeProviderOrCustomerOrder }}<span class="important-data">*</span></span>
              </template>
            </v-select>
          </v-col>
          <v-col cols="12">
            <v-textarea v-model="sComments" maxlength="255" label="Comentarios iniciales" :persistent-placeholder="true"  placeholder="Comentarios iniciales..." class="global-text-field" color="var(--primary-color-border-menu-profile)"
              background-color="var(--primary-color-menu)" @change="validateFormOperation()" outlined>

            </v-textarea>

          </v-col>
        </v-row>
      </v-container>
      <!-- #endregion INPUTS ADD OPERATIONS -->

      <!--#region TABLE RAW MATERIALS (TEMPORAL - ALTA DE UNA OPERACIÓN Y DETALLE DE OPERACION, ESPECIALISTA Y OPERACIONES DE CAJA) -->
      <TableRawMaterialsGlobal @getItem="getItemMaterial" @setDeleteItem="setDeleteItem"
        :aHeadersRawMaterials="aHeadersRawMaterials" :aRawMaterial="aRawMaterial" :oItem="oItem"
        :bAdminPermissions="true" :bBlockAddRawMaterial="bBlockAddRawMaterial" />
      <!--#endregion TABLE RAW MATERIALS -->

      <!-- #region CONTENT BUTTONS  -->
      <v-container fluid>
        <v-divider class="divider-global mt-5"></v-divider>

        <div class="content-btns-accions" v-show="bAdminOPPending">

          <v-spacer></v-spacer>
          <!-- #region BOTON DE DESCARTAR CAMBIOS-->
          <div class="content-btn-second">
            <v-btn class="global-btn-neutral" @click="setCancel()">
              Cancelar
            </v-btn>
          </div>
          <!-- #endregion -->

          <!-- #region BOTONES DE MODIFICAR Y GUARDAR CAMBIOS-->
          <div v-show="bAdminOPPending" class="content-btn-primary">
            <v-btn class="global-btn-primary btn-save-change" :disabled="!bAddOperation" :loading="bLoading"
              @click="addOperation()">
              Añadir
            </v-btn>
          </div>
          <!-- #endregion -->
        </div>
      </v-container>
      <!-- #endregion CONTENT BUTTONS  -->

    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      screenWidth: 0,
      screenHeight: 0,
      heightMax: 0,
      bAddOperation: false,
      bLoading: false,
      itemsTypeOperation: [{ text: "Orden de compra", value: 1 }, { text: "Pedido de venta", value: 2 }],
      itemsTypeProvider: [{ text: "Empresa", value: 1 }, { text: "Persona", value: 2 }],
      itemsTypeProviderOrCustomerOrder: [],
      itemsBreadcrumbs: [
        {
          text: "Especialista",
          disabled: false,
          to: { name: "Provider" },
        },
        {
          text: "Creación de un especialista",
          disabled: true,
          to: { name: "" },
        },
      ],
      sLabelTypeProviderOrCustomerOrder: "",
      sTypeOperation: 1,
      sDriverLicensePlate: "",
      sDriverFullName: "",
      sTypeProvider: 1,
      sTypeProviderOrCustomerOrder: null,
      sComments:"",
      sRawMaterialId: "",


      aHeadersRawMaterials: [
      ],
      aRawMaterial: [],
      bCloseBtnRawMaterial: false,
      oItem: {},
      bBlockAddRawMaterial: false

    }
  },
  beforeMount() {
    this.$store.commit("setsNameDetail", "");
    this.setHeadersTable();
    this.setTypeProviderOrCustomerOrder();
    this.getTypeProviderOrCustomerOrder();

  },
  updated() {
    this.handleResize();
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    //#region RESIZE PAGE
    matchHeight() {
      this.$nextTick((e) => {
        if (this.$refs.iHeaderDetail !== undefined) {
          this.heightMax = this.$refs.iHeaderDetail.clientHeight;
          this.heightMax = this.heightMax + 123; //123 IS NAVBAR
        }
        if (window.innerWidth > 600) {
          this.screenHeight = window.innerHeight - 100;
        } else {
          this.screenHeight = window.innerHeight - 100;
        }
      });
    },
    handleResize: function () {
      this.matchHeight();
    },
    //#endregion RESIZE PAGE
    validateFormOperation() {
      this.bAddOperation =
        this.sDriverFullName.trim() !== "" &&
        this.sTypeProviderOrCustomerOrder !== null &&
        this.aRawMaterial.length > 0;
    },
    setHeadersTable() {
      let aHeaders = [
        {
          text: "#",
          sortable: false,
          value: "dNumTransation",
          class: "global-header-table",
          cellClass: "global-body-table ",
        },
        {
          text: "Código",
          sortable: false,
          value: "sCodeRawMaterial",
          class: "global-header-table",
          cellClass: "global-body-table ",
        },
        {
          text: "Nombre de la materia",
          sortable: false,
          value: "sNameRawMaterial",
          class: "global-header-table",
          cellClass: "global-body-table ",
        },
        {
          text: "Peso",
          sortable: false,
          value: "dWeightRawMaterial",
          class: "global-header-table",
          cellClass: "global-body-table ",
        },
        {
          text: "Nombre del responsable",
          sortable: false,
          value: "sManagerRawMaterial",
          class: "global-header-table",
          cellClass: "global-body-table ",
        },
        {
          text: "Acciones",
          value: "accions",
          align: "center",
          class: `global-header-table ${this.bAdminOPPending ? "" : "d-none"
            }  global-header-accions  global-col-header-accion`,
          cellClass: `global-body-table ${this.bAdminOPPending ? "" : "d-none"
            }  global-body-accions global-col-body-accion`,
        },
      ];
      this.aHeadersRawMaterials = aHeaders;
    },
    setTypeProviderOrCustomerOrder() {
      this.sLabelTypeProviderOrCustomerOrder = this.sTypeOperation === 1 ? 'Proveedor de la orden de la compra' : 'Vendedor de la orden de compra'
    },
    getTypeProviderOrCustomerOrder() {
      this.$store
        .dispatch("getPermissionsByUserGlobal")
        .then((resp) => {
          DB.get(`${URI}/api/sp/v1/${this.setNameType()}`, {
            params: {
            },
            headers: {
              Authorization: "Bearer " + this.$store.state.sToken,
            },
          })
            .then((response) => {
              this.fillData(response.data.results)
            })
            .catch((error) => {
              this.bLoadingTable = false;
              this.Error(error.response.data);
            });
        })
        .catch((err) => {
          this.Error(err);
        });
    },
    setNameType() {
      if (this.sTypeOperation == 1) {
        switch (this.sTypeProvider) {
          case 1:
            return `vendors/enterprises`
          case 2:
            return `vendors/person`
        }
      } else {
        return `customers`
      }

    },
    fillData(aResult) {
      if (this.sTypeOperation == 1) {
        switch (this.sTypeProvider) {
          case 1:
            this.itemsTypeProviderOrCustomerOrder = aResult.map((e) => {
              return {
                text: e.sPublicName,
                value: e.sVendorEnterpriseId
              }
            })
            break;
          case 2:
            this.itemsTypeProviderOrCustomerOrder = aResult.map((e) => {
              return {
                text: e.sFullName,
                value: e.sVendorPersonId
              }
            })
            break;
        }
      } else {
        this.itemsTypeProviderOrCustomerOrder = aResult.map((e) => {
          return {
            text: e.sPublicName,
            value: e.sCustomerId
          }
        })

      }
      this.itemsTypeProviderOrCustomerOrder.unshift({
        text: "Seleccionar tipo de proveedor...",
        value: null,
        disabled: true,
      });
    },
    getItemMaterial(oItem) {
      if (this.aRawMaterial.length > 0) {
        this.Warning("Solo se puede agregar una materia prima", "Elimina la materia prima de la tabla para agregar una nueva")
      } else {
        this.aRawMaterial.push(oItem[0])

      }
    },
    setDeleteItem() {
      this.aRawMaterial = [];
    },
    addOperation() {
      this.bLoading = true;
      this.$store
        .dispatch("getPermissionsByUserGlobal")
        .then((resp) => {
          if (this.bAdminOPPending) {
            const payload = this.getParamsAddOperation(),
              config = {
                headers: {
                  Authorization: "Bearer " + this.$store.state.sToken,
                },
              };
            DB.post(`${URI}/api/sp/v1/orders/general/${this.sTypeOperation === 1 ? 'purchase' : 'sales'}/small-scale`, payload, config)
              .then((response) => {
                this.Success(response.data.message);
                this.setCancel();

              })
              .catch((error) => {
                this.bLoading = false;
                this.Error(error.response.data);
              });
          } else {
            this.Error(this.$store.state.oError403);
          }
        })
        .catch((err) => {
          this.Error(err);
        });
    },
    getParamsAddOperation() {
      switch (this.sTypeOperation) {
        case 1:
          return {
            sVendorId: this.sTypeProviderOrCustomerOrder,
            sDriverLicensePlate: this.sDriverLicensePlate,
            sDriverFullName: this.sDriverFullName,
            sRawMaterialId: this.aRawMaterial[0].sRawMaterialId,
            sComments:this.sComments
          };
        case 2:

          return {
            sCustomerId: this.sTypeProviderOrCustomerOrder,
            sDriverLicensePlate: this.sDriverLicensePlate,
            sDriverFullName: this.sDriverFullName,
            sRawMaterialId: this.aRawMaterial[0].sRawMaterialId,
            sComments:this.sComments
          };

        default:
          return {

          };
      }
    },
    setCancel() {
      this.$router.push({ name: "OPPending" }).catch((err) => {
      })
    }
  },
  computed: {
    bShowOPPending() {
      return this.$store.state.bShowOPPending;
    },
    bAdminOPPending() {
      return this.$store.state.bAdminOPPending;
    },
  },
  watch: {
    sTypeOperation() {
      this.setTypeProviderOrCustomerOrder();

      this.getTypeProviderOrCustomerOrder();

      this.validateFormOperation();

    },
    sTypeProvider() {
      this.sTypeProviderOrCustomerOrder = null;
      this.getTypeProviderOrCustomerOrder();
      this.validateFormOperation();
    },
    aRawMaterial() {
      this.bBlockAddRawMaterial = this.aRawMaterial.length > 0
      this.validateFormOperation();
    },


  }

}
</script>

<style scoped>
.content-btns-accions {
  display: flex;
  width: 100% !important;
  justify-content: flex-end;
  padding: 0px 0px 0px 12px;
  margin-top: 25px;
  margin-bottom: 5px;
}

.content-btn-primary {
  width: 200px;
}

.content-btn-second {
  width: 200px;
  margin-right: 15px;
}

/**************************MODO RESPONSIVO ********************/
/* XS */
@media (max-width: 599px) {
  .demoColorResponsive {
    background-color: rgb(190, 136, 226);
  }

  .content-btns-accions {
    display: block;
    width: 100% !important;
  }

  .content-btn-second {
    width: 100%;
    margin-right: 0px;
    margin-bottom: 15px;
  }

  .content-btn-primary {
    width: 100%;
  }

}

/* SM */
@media (min-width: 600px) and (max-width: 959px) {
  .demoColorResponsive {
    background-color: rgb(136, 226, 151);
  }
}

/* MD */
@media (min-width: 960px) and (max-width: 1264px) {
  .demoColorResponsive {
    background-color: rgb(201, 122, 83);
  }
}

/* LG */
@media (min-width: 1264px) and (max-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(204, 198, 109);
  }
}

/* XL */
@media (min-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(113, 199, 201);
  }
}
</style>